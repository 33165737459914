.ProfileSidebar_all {
  min-height: 90vh;
  border-right: 1px solid black;
  background-color: #3f3d56;
}
.ProfileSidebar_bigDiv {
  display: flex;
  flex-direction: column;
  width: 60px;
  transition: 0.5s ease-in-out;
  overflow: hidden;
}
/* .ProfileSidebar_bigDiv:hover {
  width: 210px;
  cursor: pointer;
} */
/* .profileSIdebar_divs {
  padding: 18px;
} */
/* .profileSIdebar_divs_back_white {
  background-color: #FFFFFF;
} */



.ProfileSidebar_bigDiv .line_div >span{
  display: none;
  color: white;
}

.ProfileSidebar_bigDiv .line_div .line_div_profile_icon{
  padding-left: 18px;
  display: block;
}



.ProfileSidebar_all .line_divActive >span{
  display: none;
  color: #3f3d56;
}
.ProfileSidebar_all:hover .line_div >span{
  display: block;
}

.line_divActive .line_div_profile_icon svg path{
  stroke: #3f3d56;
}

.line_div {
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 25px;
  width: 220px;
  color: white;
  height: 50px;
}
.line_divActive {
  /* color: white;  */
  background-color: blueviolet;
  color: #4340BB !important; 
  /* filter: invert(0%) sepia(49%) saturate(4337%) hue-rotate(338deg) brightness(17%) contrast(86%);  */
   background-color: white; 
   display: flex;
  align-items: center;
  justify-content: start;
  gap: 25px;
  width: 220px;
  color: black;
  background-color: white; 
}




@media(max-width:1024px){
.ProfileSidebar_bigDiv{
  padding-top: 80px;
}
}


