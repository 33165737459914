/*  headers start ****************** */
.museumPageHeader {
   height: 500px;
}
.parHeader_img {
   position: fixed;
   z-index: -100;
   width: 100%;
}

.parHeader_img::before {
   content: '';
   position: absolute;
   top: 0;
   bottom: 0;
   left: 0;
   right: 0;
   background-color: #373433;
   opacity: 0.6;
}

.par_pageHeader-txt {
   height: 100%;
   display: flex;
   justify-content: center;
   align-items: center;
}

.par_txt {
   max-width: 1400px;
   text-align: center;
}

.par_txt > h1 {
   /* color: #ffffff; */
   color: var(--main_font_color);
   letter-spacing: 10px;
   font-weight: 100;
}

/*  headers end ****************** */
.museumPage_section {
   padding: 20px 20px 10px;
   /* background-color: #fffefe; */
   /* padding-bottom: 340px; */
   min-height: 900px;
}
.museumPage_sectionOne {
   padding: 20px 20px 10px;
   background-color: #fffefe;
   /* padding-bottom: 340px; */
   min-height: 900px;
}

.museumPage_section-title {
   display: flex;
   flex-direction: column;
   gap: 20px;
   justify-content: center;
   align-items: center;
   padding-bottom: 32px;
}

.museumPage_section-title h2 {
   font-weight: 500;
   letter-spacing: 5px;
   color: var(--main_font_color);
   text-transform: uppercase;
}
.museumPage_section-title h3 {
   font-weight: 400;
    letter-spacing: 1px;
    color: var(--main_font_color);
    text-align: center;
}

.museumPage_section-title-lines_div {
   display: flex;
   justify-content: center;
   align-items: center;
   gap: 50px;
}

/* .aboute_usPage_block_title h3{
   color: var(--main_font_color);
   letter-spacing: 2px;
   text-align: center;
   font-weight: 100;
} */

/*  searchBlock start ****************** */

.parent_searchBlock {
   height: 110px;
   background-color: #ffffff;
   box-shadow: 0px 0px 6px -5px;
   border-radius: 10px;
   display: flex;
   align-items: center;
   padding: 0 20px;
}

.parent_searchBlock-title {
   display: flex;
   justify-content: start;
   align-items: center;
   gap: 3px;
   padding: 16px 0;
}

.searchBlock {
   max-width: 370px;
   display: flex;
   align-items: center;
   justify-content: space-between;
   gap: 10px;
   border: 1px solid #dadada;
   flex-basis: 240px;
   padding: 10px 16px 10px 16px;
   border-radius: 6px;
   position: relative;
   cursor: pointer;
}

.searchBlock > h4 {
   color: #a3a3a3;
   font-family: globalFont;
   line-height: 19px;
   font-size: 13px;
   font-weight: 400;
}

.parent_listRegion {
   position: absolute;
   top: 50px;
   left: 0;
   right: 0;
   background-color: #ffffff;
   box-shadow: 0px 0px 6px -5px;
   /* border-radius: 10px; */
   /* padding: 10px; */
   transition: 0.4s;
   z-index: 3;
   padding-bottom: 60px;
   box-shadow: 0 0 7px -4px;
}
.listRegion {
   /* display: flex;
   flex-wrap: wrap;
   gap: 10px; */
   /* padding: 10px; */
}
.listRegion_p {
   padding: 0 22px;
   cursor: pointer;
}
.listRegion_p > p {
   padding: 20px 0;
   border-bottom: 1px solid #ecebeb;
   font-family: globalFont;
   line-height: 19px;
   font-weight: 400;
   display: flex;
   align-items: center;
   gap: 15px;
}
.listRegion_p:hover {
   background-color: #f6f5f5;
   border-bottom: 1px solid #ecebeb;
}

.searchOll {
   padding: 20px 22px;
   border-bottom: 1px solid #ecebeb;
   display: flex;
   align-items: center;
   gap: 15px;
   cursor: pointer;
}
.searchOll:hover {
   background-color: #f6f5f5;
}

/*  museumList start ****************** */

.par_museumList {
   display: flex;
   flex-wrap: wrap;
   justify-content: center;
   gap:20px;
   margin: 30px 0;
   align-items: start;
   padding-bottom: 50px;
}

.par_MuseumMinBlock {
   /* max-width: 380px; */
   width: 30%;
   min-height: 380px;
}

.MuseumMinBlock {
   /* min-height: 300px;
   position: relative; */
}

.MuseumMinBlock-parImg {
   padding:15px;
   background-color: rgba(217, 217, 217, 0.4);
   /* min-height: 380px; */
}

.MuseumMinBlock_img {
   /* max-width: 330px; */
   height: 270px;
   background-position: center;
   background-size: cover;
   position: relative;
}

.MuseumMinBlock_img-parLink {
   position: absolute;
   left: 0;
   bottom: 0;
   right: 0;
   top: 0;
   background-color: rgba(231, 231, 231, 0.74);
   display: flex;
   justify-content: center;
   align-items: center;
   opacity: 0;
   transition: 0.4s;
}

.MuseumMinBlock-parImg:hover .MuseumMinBlock_img-parLink {
   opacity: 1;
}

.MuseumMinBlock_img-link {
   font-family: globalFont;
   font-weight: 400;
   line-height: 21px;
   font-size: 11px;
   background-color: rgba(213, 170, 114, 1);
   color: rgba(255, 255, 255, 1);
   width: 210px;
   height: 38px;
   display: flex;
   justify-content: center;
   align-items: center;
}

.par_MuseumMinBlock-description {
   width: 100%;
   opacity: 0.7;
   padding: 13px;
}
.block-description {
   display: flex;
   flex-direction: column;
   gap: 5px;
   justify-content: space-between;
}

.museum_name,
.museum_region,
.museum_description {
   font-family: globalFont;
   font-weight: 400;
   line-height: 21px;
}

.App .museum_name {
   font-size: 18px;
}

.App.small .museum_name{
   font-size: 16px;
}

.App.medium .museum_name{
   font-size: 22px;
}

.App.large .museum_name{
   font-size: 24px;
}


.museum_name {
   color: #b26705;
   display: -webkit-box;
   -webkit-line-clamp: 1;
   -webkit-box-orient: vertical;
   overflow: hidden;
   text-overflow: ellipsis;
}
.museum_region {
   display: flex;
   align-items: center;
   gap: 12px;
}
.museum_description,
.museum_region {
   font-size: 13px;
   color: #575757;
}

.par_museum_pagButton {
   display: flex;
   justify-content: center;
   align-items: center;
   gap: 20px;
}
.museum_pagButton {
   background-color: #ffffff;
   border: none;
   width: 40px;
   box-shadow: 0 0 5px -3px;
   border-radius: 4px;
}

/*  museumList end ****************** */

/*  museumOne start ****************** */
.museumOne_parent-section1 {
   display: flex;
   gap: 20px;
}

.museumOne_title {
   font-family: globalFont;
   /* font-weight: 400; */
   /* font-size: 14px; */
   line-height: 24px;
   color: #000000;
}

.App .educationalPrograms-par-button {
   font-family: globalFont;
   font-weight: bold;
   font-size: 15px;
   line-height: 20px;
   color: #765324;
   cursor: pointer;
   /* text-decoration: underline; */
   border-bottom: 1px solid #765324;
}

.museumOne-blockLeft {
   flex-grow: 5;
   max-width: 71%;
}
.museumOne-blockRigth {
   flex-grow: 2;
   max-width: 450px;
}

.blockRigth_styles {
   box-shadow: 0 3px 7px -5px;
}

.blockRigth_styles h4 {
   font-family: globalFont;
   /* font-weight: 400; */
   font-size: 14px;
   line-height: 21px;
   color: black;
   margin-bottom: 10px;
}

.blockRigth_styles {
   color: #575757;
   line-height: 19px;
   font-weight: 400;
   font-size: 13px;
}

.blockRigth_styles-parPhone {
   display: flex;
   /* justify-content: center; */
   align-items: center;
   gap: 7px;
   flex-wrap: wrap;
}
.blockRigth_styles-parPhone a {
   color: #575757;
}

.Invate_par {
   position: relative;
   cursor: pointer;
   overflow: hidden;
}
.Invate_child {
   position: absolute;
   top: -4px;
   left: -41px;
   background-color: #b26705;
   padding: 5px 10px;
   opacity: 0;
   transform: translateX(310px);
   transition: 0.6s;
}
.Invate_child > p {
   font-family: globalFont;
   font-weight: 400;
   font-size: 16px;
   line-height: 21px;
   color: #ffffff;
}

.museumOne_pageStyle {
   background-color: #ffffff;
   padding: 15px;
   margin-bottom: 20px;
   display: flex;
   flex-direction: column;
   gap: 15px;
   /* max-width: 400px; */
}

.header_right-button {
   display: flex;
   gap: 10px;
   align-items: center;
}

.header_right-button h4 {
   margin: 0;
}

.MuseumOne_parent-imgList {
   display: flex;
   justify-content: center;
   align-items: center;

   padding-top: 20px;
}

.MuseumOne_parent-imgList_img {
   width: 300px;
   height: 350px;
   background-position: center;
   background-size: cover;
   cursor: pointer;
}

.MuseumOne_parent-imgList_imgCenter {
   width: 350px;
   height: 430px;
   z-index: 0;
   background-position: center;
   background-size: cover;
}

.museumOne_pageStyle-parentMinBlock {
   display: flex;
   flex-direction: column;
   gap: 10px;
}

.educationalPrograms-minBlock {
   display: flex;
   flex-direction: column;
   gap: 10px;
   border-bottom: 1px solid rgba(0, 0, 0, 0.2);
   /* border-radius: 5px; */
   padding: 10px 0;
}

.educationalPrograms-minBlock-header {
   display: flex;
   justify-content: space-between;
   align-items: center;
   position: relative;
   overflow: hidden;
}

.educationalPrograms-minBlock-header-title {
   font-family: globalFont;
   font-weight: 400;
   font-size: 13px;
   line-height: 20px;
   color: #575757;
}

.educationalPrograms-minBlock-parDropDown::-webkit-scrollbar {
   width: 7px;
}
.educationalPrograms-minBlock-parDropDown::-webkit-scrollbar-thumb {
   background: #d9d9d9;
   border-radius: 10px;
}

.educationalPrograms-minBlock-parDropDown p {
   font-family: globalFont;
   font-weight: 400;
   font-size: 13px;
   line-height: 20px;
   color: #575757;
}
.educationalPrograms-minBlock-parDropDown p span {
   font-weight: 700;
}

.educationalPrograms-minBlock_count {
   display: flex;
   flex-wrap: wrap;
   align-items: center;
   gap: 10px;
}
.educationalPrograms-minBlock_count > span {
   display: flex;
   align-items: center;
   gap: 10px;
}

.par-contactMinBlock {
   display: flex;
   align-items: center;
   gap: 10px;
}

/*  sviper start ******************************** */

.swiper {
   width: 100%;
   height: 100%;
}

.OurEventsSwiper {
   height: 250px;
   z-index: 0 !important;
}

.OurEventsSwiper > .swiper-button-prev {
   right: 45px;
   top: var(--swiper-navigation-top-offset, 84%);
   left: auto;
}
.OurEventsSwiper > .swiper-button-next {
   top: var(--swiper-navigation-top-offset, 84%);
   right: var(--swiper-navigation-sides-offset, 10px);
   left: auto;
}

.OurEventsSwiper > .swiper-button-next,
.OurEventsSwiper > .swiper-button-prev {
   color: var(--swiper-navigation-color, #3f3d56);
   background-color: #ffffff;
   font-size: 16px;
   width: 24px;
   height: 24px;
   box-shadow: 4px 6px 11px -4px;
   border-radius: 50%;
}
.OurEventsSwiper > .swiper-pagination-fraction,
.swiper-pagination-custom,
.OurEventsSwiper > .swiper-horizontal > .swiper-pagination-bullets,
.swiper-pagination-bullets,
.swiper-pagination-horizontal {
   bottom: var(--swiper-pagination-bottom, 44px) !important;
   top: var(--swiper-pagination-top, auto);
   left: 21px;
   width: auto !important;
   /* background-color: #C4C4C4; */
   height: 17px;
}
/* .swiper-horizontal > .swiper-pagination-bullets, .swiper-pagination-bullets.swiper-pagination-horizontal {
    bottom: var(--swiper-pagination-bottom, 44px) !important;
    top: var(--swiper-pagination-top, auto);
    left: 0;
    width: auto !important;
} */

.OurEventsSwiper > .swiper-pagination > .swiper-pagination-bullet {
   width: var(--swiper-pagination-bullet-width, var(--swiper-pagination-bullet-size, 20px));
   height: var(--swiper-pagination-bullet-height, var(--swiper-pagination-bullet-size, 7px));
   display: inline-block;
   border-radius: 0;
   background: #c4c4c4;
   opacity: var(--swiper-pagination-bullet-inactive-opacity, 0.2);
   margin: 0 !important;
}
.OurEventsSwiper > .swiper-pagination > .swiper-pagination-bullet-active {
   opacity: var(--swiper-pagination-bullet-opacity, 1);
   background: #3f3d56;
}

.OurEventsSwiper > .swiper-button-prev:after,
.OurEventsSwiper > .swiper-button-next:after {
   font-family: swiper-icons;
   font-size: 14px;
   text-transform: none !important;
   letter-spacing: 0;
   font-variant: initial;
   line-height: 1;
   font-weight: 700;
}
.swiper-slide {
   display: flex;
   justify-content: center;
   align-items: center;
}

.swiper-slide img {
   display: block;
   width: 100%;
   height: 100%;
   object-fit: cover;
}

/*  sviper end ******************************** */

/* educationalPrograms-par start ****************************** */

.educationalPrograms-par {
   max-width: 71%;
}
.educationalPrograms-par-txt {
   font-family: globalFont;
   font-weight: 400;
   font-size: 10px;
   line-height: 20px;
   color: #b26705;
   margin-bottom: 15px;
}

/* educationalPrograms-par end ****************************** */

.museumOneVirtualTour-iframePar {
   height: 400px;
   max-width: 700px;
   margin-top: 20px;
}

/*  OurEvents start ***************************  */

.block_eventMuseum {
   display: flex;
   height: 170px;
   max-width: 470px;
}

.block_eventMuseum-img {
   width: 50%;
}
.block_eventMuseum-desc {
   width: 50%;
   padding: 15px 24px;
   display: flex;
   justify-content: center;
   align-items: center;
   background-color: #f6f5f5;
   box-sizing: content-box;
}
.block_eventMuseum-desc > div {
   width: 180px;
   display: flex;
   flex-direction: column;
   /* gap: 16px; */
   align-items: start;
   justify-content: space-between;
   height: 100%;
}
.block_eventMuseum-desc p {
   font-family: globalFont;
   font-weight: 400;
   font-size: 13px;
   line-height: 19px;
   text-align: start;
}

/*  OurEvents end ***************************  */

/*  museumOne end ****************** */

/*   CustomNotification start****************** */

.CustomNotification-par {
   width: 320px;
   height: 100px;
   display: flex;
   gap: 14px;
   align-items: center;
   border-radius: 10px;
   box-shadow: 0 0 33px -20px;
   position: fixed;
   top: 100px;
   right: 50px;
   background-color: aliceblue;
   transition: 800ms;
   transform: translateX(400px);
   z-index: 9999999;
   overflow: auto;
}
.CustomNotification-block_left {
   height: 100%;
   border-radius: 10px 0 0 10px;
   width: 15px;
}
.CustomNotification-block_right {
   display: flex;
   /* height: 100%; */
   /* width: 109%; */
   justify-content: center;
   align-items: center;
   gap: 12px;
   padding: 10px 10px 10px 0px;
}
.CustomNotification-block_right > p {
   max-width: 220px;
}


.notification_modal_request{
   position: fixed;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   background-color: rgba(0, 0, 0, 0.6);
   z-index: 999999999999;
   display: flex;
   justify-content: center;
   align-items: center;
}

.notification_modal_requests_block{
   width: 500px;
   /* height: 500px; */
   padding: 50px 20px;
   background-color: white;
   border-radius: 10px;
   display: flex;
   flex-direction: column;
   gap: 40px;
   align-items: center;
   border-left: 30px solid rgb(7, 100, 7);
   position: relative;
}

.notification_modal_request_block_icons{
   display: flex;
   gap: 10px;
   align-items: center;
   flex-direction: column;
   justify-content: center;
}


span.notification_modal_request_close{
   position: absolute;
   top: 10px;
   right: 10px;
   cursor: pointer;
   font-family: monospace;
   font-size: 20px;
   color: red;
}


.museum_otherservices{
   display: flex;
   flex-direction: column;
   gap: 30px;
   padding: 50px 0;
}

.otherServices_items{
   display: flex;
   flex-direction: column;
   gap: 10px;
   width: 715px;
}

.otherServices_item{
   display: flex;
   gap: 10px;
   align-items: start;
}


.oneService{
   display: flex;
   align-items: center;
   gap: 10px;
   font-family: globalFont;
   font-weight: 400;
   line-height: 19px;
   width: 100%;
   justify-content: space-between;
   border-bottom: 1px solid gray;
   padding-bottom: 10px;
}

.museum_otherservices_header{
   display: flex;
   flex-direction: column;
   gap: 10px;
}

.museum_otherservices_title{
   font-family: globalFont;
    /* font-weight: 400; */
    /* font-size: 14px; */
    line-height: 24px;
    color: #000000;
    text-transform: uppercase;
}


.museum_otherservices_sub_title{
   font-family: globalFont;
   font-weight: 400;
   line-height: 19px;
   color: var(--second_font_color);
}

p.oneService_name {
   font-family: var(--globalFont); 
   font-weight: 400;
   line-height: 19px;
   width: 80%;
   height: 20px;
   white-space: nowrap; 
   text-overflow: ellipsis;
   overflow: hidden;
}

p.oneService_price{
   font-family: globalFont;
   font-weight: 400;
   line-height: 19px;
   color: var(--second_font_color);
}


p.museumTicket_formChild_header_span{
   font-family: globalFont;
   font-weight: 400;
   line-height: 19px;
   font-size: 13px;
   color: var(--second_font_color);
   text-align: center;
}
/*   CustomNotification end****************** */

@media (max-width: 1400px) {
   .museumPageHeader {
      height: 320px;
   }
}
@media (max-width: 1040px) {
   .par_MuseumMinBlock {
      width: 45%;
   }
   /* .museumPage_section {
      padding: 20px 20px 440px;
   } */
}
@media (max-width: 900px) {
   .museumOne_parent-section1 {
      flex-wrap: wrap;
   }
   .museumOne-blockLeft {
      max-width: 100%;
   }
   .educationalPrograms-par {
      max-width: 100%;
   }

   .otherServices_items{
      width: 500px;
   }
}
@media (max-width: 800px) {
   .museumPageHeader {
      height: 150px;
   }

   .par_txt > h1 {
      font-size: 25px;
   }

   .museumOne-blockLeft {
      max-width: 100%;
   }

   .museumPage_section-title-lines_div {
      flex-direction: column;
   }
}
@media (max-width: 650px) {
   .par_MuseumMinBlock {
      width: 100%;
   }
   /* .museumPage_section {
      padding: 20px 20px 600px;
   } */

   .otherServices_items{
      width: 420px;
   }
}
@media (max-width: 500px) {
   .MuseumOne_parent-imgList {
      /* flex-direction: column; */
   }
}
@media (max-width: 430px) {
   .museumPageHeader {
      height: 150px;
   }
   .par_txt > h1 {
      font-size: 15px;
   }
   .museumOne_pageStyle > h3 {
      font-size: 20px;
   }

   .museumPage_section-title h2 {
      font-size: 35px;
   }

   .otherServices_items{
      width: 340px;
   }
}

:root {
   --status-color: teal;
}

.status {
   position: fixed;
   width: 30px;
   height: 30px;
   background-color: var(--status-color);
   border-radius: 50%;
   animation: status 3s ease infinite;
   z-index: 20000;
}

@keyframes status {
   0% {
      box-shadow: none;
   }
   50% {
      box-shadow: 0 0 5px 3px var(--status-color), 0 0 25px 7px var(--status-color);
   }
   100% {
      box-shadow: none;
   }
}
