.draw-border_2 {
    box-shadow: inset 0 0 0 1px #cea670;
    color: #cea670;
    transition: color 0.25s 0.0833333333s;
    position: relative;
  }
  .draw-border_2::before, .draw-border_2::after {
    border: 0 solid transparent;
    box-sizing: border-box;
    content: "";
    pointer-events: none;
    position: absolute;
    width: 0;
    height: 0;
    bottom: 0;
    right: 0;
  }
  .draw-border_2::before {
    border-bottom-width: 1px;
    border-left-width: 1px;
  }
  .draw-border_2::after {
    border-top-width: 1px;
    border-right-width: 1px;
  }
  .draw-border_2:hover {
    color: black;
  }
  .draw-border_2:hover::before, .draw-border_2:hover::after {
    border-color: black;
    transition: border-color 0s, width 0.25s, height 0.25s;
    width: 100%;
    height: 100%;
  }
  .draw-border_2:hover::before {
    transition-delay: 0s, 0s, 0.25s;
  }
  .draw-border_2:hover::after {
    transition-delay: 0s, 0.25s, 0s;
  }
  
  .btn_2 {
    background: none;
    border: none;
    cursor: pointer;
    line-height: 1.5;
    font: 100 1.2rem "Roboto Slab", sans-serif;
    padding: 10px 2em;
    letter-spacing: 0.05rem;
  }
 
  