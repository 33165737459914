.ReactModal__Overlay {
   z-index: 999999;
}
.TicketMuseumCatalog-parent {
   padding: 0 40px 25px;
   max-width: 430px;
}

.BuyTicketBlock-listPar {
   display: flex;
   flex-direction: column;
   gap: 16px;
   margin: 13px 0 23px;
}

.BuyTicketBlock-list-menu {
   display: flex;
   justify-content: space-between;
   align-items: center;
   gap: 15px;
}
/* .BuyTicketBlock-list-menu span {
   color: #a6a6a6;
} */

.TicketMuseumCatalog-buttonBlock {
   display: flex;
   justify-content: center;
   gap: 10px;
}

.TicketMuseumCatalog-buttonBlock button:disabled {
  background-color: #a6a6a6 !important;
  cursor: not-allowed;
}

.BuyTicketBlock-list-menu p {
   font-family: globalFont;
   font-weight: 400;
   font-size: 16px;
   line-height: 24px;
   color: #000000;
}

.ticketModal-close {
   width: 30px;
   height: 40px;
   border-radius: 50px;
   display: flex;
   justify-content: flex-end;
   align-items: center;
   cursor: pointer;
   color: #b26705;
   width: 100%;
   padding: 10px 11px;
}

.museumTicket-formChild {
   max-width: 400px;
}

.museumTicket-formChild-header > p {
   font-family: globalFont;
   font-weight: 400;
   font-size: 16px;
   line-height: 24px;
   color: #000000;
   text-align: center;
}

.attention {
   display: flex;
   align-items: center;
   gap: 5px;
}
.attention span {
   font-family: globalFont;
   font-weight: 400;
   font-size: 12px;
   line-height: 24px;
   color: #a6a6a6;
}

.BuyTicketBlock-list-Button {
   display: flex;
   align-items: center;
   gap: 10px;
}
.BuyTicketBlock-list-Button span {
   cursor: pointer;
   display: flex;
   color: #a6a6a6;
}

.BuyTicketBlock-list-priceOne p {
   font-family: globalFont;
   font-weight: 400;
   font-size: 16px;
   line-height: 24px;
   color: #000000;
   display: flex;
   gap: 5px;
   justify-content: space-between;
   align-items: center;
}

.BuyTicketBlock-list-price {
   box-shadow: 0 4px 15px -14px;
   padding: 20px 10px;
   margin: 24px 0;
}
.BuyTicketBlock-list-price > div {
   max-width: 300px;
}
.BuyTicketBlock-list-price p {
   font-family: globalFont;
   font-weight: 400;
   font-size: 14px;
   line-height: 18px;
   color: #a6a6a6;
   margin: 5px 0;
   display: flex;
   gap: 5px;
   justify-content: space-between;
   align-items: center;
}
.BuyTicketBlock-list-price span {
   min-width: 70px;
}

.BuyTicketBlock-list-totalPrice > p {
   display: flex;
   justify-content: center;
   align-items: center;
   font-family: globalFont;
   font-weight: 400;
   font-size: 16px;
   line-height: 20px;
   color: #3f3d56;
   margin: 10px 0 14px;
}
.BuyTicketBlock-list-totalPrice > p span {
   font-family: globalFont;
   font-weight: 400;
   font-size: 16px;
   line-height: 20px;
   color: #000000;
   margin-left: 10px;
}
.newStyleBtn::before {
   border: none;
}
.newStyleBtn::after {
   border: none;
}

.AbonementTicket_totalPrice {
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   gap: 12px;
   box-shadow: 0 4px 15px -14px;
   margin: 30px 0;
   padding: 20px 10px;
}
.AbonementTicket_totalPrice > p {
   font-family: globalFont;
   font-weight: 400;
   font-size: 16px;
   line-height: 20px;
   color: #000000;
}
.AbonementTicket_totalPrice > span {
   font-family: globalFont;
   font-weight: 400;
   font-size: 12px;
   line-height: 25px;
   color: #a3a3a3;
}

.BuyTicketBlock-list-warning p {
   display: flex;
   justify-content: center;
   align-items: center;
   color: red;
   font-size: 13px;
   font-family: globalFont;
   margin: 10px 0 14px;
}

/* icket form start ................................. */

.museumTicket-formChild-inbut > input {
   border: 1px solid #aaaaaa;
   padding: 14px 16px;
   min-width: 300px;
}

.formChild-inbut {
   border: 1px solid #aaaaaa;
   padding: 14px 16px;
   min-width: 300px;
}

.museumTicket-selectIcon {
   position: absolute;
   right: 10px;
   top: 14px;
}

.ticketForm-blockradio {
   display: flex;
   justify-content: space-between;
   min-width: 300px;
}
.ticketForm-blockradio > label {
   color: #aaaaaa;
   display: flex;
   align-items: center;
   gap: 8px;
   font-family: globalFont;
   font-weight: 400;
   font-size: 12px;
   line-height: 20px;
}

/* icket form end ................................. */

@media (max-width: 500px) {
   .TicketMuseumCatalog-parent {
      padding: 0 20px 25px;
   }
}
