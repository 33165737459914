
@font-face {
    font-family: 'globalFont';
    src: url('../src/fonts/OpenSans-VariableFont_wdth-wght.ttf') format('truetype');
  }

*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: 'globalFont'
}
.container{
    max-width: 1440px;
    margin: 0 auto;
}
img{
    max-width: 100%;
}
a{
    text-decoration: none;
}
ul,ol{
    list-style: none;
}

.react-multi-carousel-dot button{
    border: 2px solid var(--second_font_color) !important;
}

.react-multi-carousel-dot--active button{
    background-color: var(--second_font_color) !important;
}

.ReactModal__Overlay {
    z-index: 9999999999 !important;
}



:root {
    --main_font_color: white;
    --second_font_color: #cea670;
  }

  @font-face {
    font-family: 'AnimFont';
    src: url('../src/fonts/BigShouldersInlineText-ExtraBold.ttf') format('truetype');
   
  }

  body{
    overflow-x: hidden !important;
  }


.App h1{
    font-size: 40px ;
}

.App h2{
    font-size: 30px ;
}

.App h3{
    font-size: 30px ;
}

.App p{
    font-size: 16px ;
}

.App  span{
    font-size: 12px ;
}

.App a{
    font-size: 16px ;
}

.App button{
    font-size: 16px ;
}


.App label{
    font-size: 16px ;
}

.App input{
    font-size: 16px ;
}

.App li {
    font-size: 16px;
}

.error_formik{
    color: red;
    font-size: 12px !important;
   }
  /* ============================================================================= */




.App.medium h1{
 font-size: 50px ;
}

.App.large h1{
    font-size: 60px ;
}

.App.small h1{
    font-size: 45px ;
}

/* =================== */

.App.medium h2{
    font-size: 40px ;
   }
   
   .App.large h2{
       font-size: 50px ;
   }
   
   .App.small h2{
       font-size: 35px ;
   }



/* ============================= */


.App.medium h3{
    font-size: 35px ;
   }
   
   .App.large h3{
       font-size: 40px ;
   }
   
   .App.small h3{
       font-size: 30px ;
   }




   /* ================================= */


   .App.medium p{
    font-size: 20px ;
   }
   
   .App.large p{
       font-size: 25px ;
   }
   
   .App.small p{
       font-size: 18px ;
   }


   /* ======================= */

   .App.medium span{
    font-size: 18px ;
   }
   
   .App.large span{
       font-size: 20px ;
   }
   
   .App.small span{
       font-size: 16px ;
   }

   /* ========================== */

   .App.medium a{
    font-size: 20px ;
   }
   
   .App.large a{
       font-size: 22px ;
   }
   
   .App.small a{
       font-size: 18px ;
   }

   .App.medium button{
    font-size: 20px ;
   }
   
   .App.large button{
       font-size: 25px ;
   }
   
   .App.small button{
       font-size: 18px ;
   }


   .App.medium label{
    font-size: 20px ;
   }
   
   .App.large label{
       font-size: 25px ;
   }
   
   .App.small label{
       font-size: 18px ;
   }


   .App.medium input{
    font-size: 20px ;
   }
   
   .App.large input{
       font-size: 25px ;
   }
   
   .App.small input{
       font-size: 18px ;
   }


   .App.medium li{
    font-size: 20px ;
   }
   
   .App.large li{
       font-size: 25px ;
   }
   
   .App.small li{
       font-size: 18px ;
   }

   .App.medium p.error_formik{
    color: red;
    font-size: 12px;
   }

   .App.large p.error_formik{
    color: red;
    font-size: 12px;
   }

   .App.small p.error_formik{
    color: red;
    font-size: 12px;
   }
   /* =================================== */



   @media(max-width: 1440px){
    .App.medium a{
        font-size: 18px ;
       }
       
       .App.large a{
           font-size: 20px ;
       }
       
       .App.small a{
           font-size: 18px ;
       }
   }


   @media(max-width: 1280px){
    .App.medium a{
        font-size: 18px ;
       }
       
       .App.large a{
           font-size: 18px ;
       }
       
       .App.small a{
           font-size: 18px ;
       }
   }

   @media(max-width: 1180px){
    .App.medium a{
        font-size: 15px ;
       }
       
       .App.large a{
           font-size: 15px ;
       }
       
       .App.small a{
           font-size: 15px ;
       }
   }

   @media(max-width: 1024px){
    .App.small h1, .App.medium h1, .App.large h1{
        font-size: 40px ;
    }
    
    .App.small h2, .App.medium h2, .App.large h2{
        font-size: 30px ;
    }
    
    .App.small h3, .App.medium h3, .App.large h3{
        font-size: 30px ;
    }
    
    .App.small p, .App.medium p, .App.large p{
        font-size: 16px ;
    }
    
    .App.small span, .App.medium span, .App.large span{
        font-size: 14px ;
    }
    
    .App.small a, .App.medium a, .App.large a{
        font-size: 16px ;
    }
    
    .App.small button, .App.medium button, .App.large button{
        font-size: 16px ;
    }
    
    
    .App.small label, .App.medium label, .App.large label{
        font-size: 16px ;
    }
    
    .App.small input, .App.medium input, .App.large input{
        font-size: 16px ;
    }
   }