
.burger_menu{
  position: relative;
  display: none;
}

#menuToggle {
  display: block;
  position: fixed;
  top: 50px;
  /* left: 15px; */
  z-index: 9999;
  -webkit-user-select: none;
  user-select: none;
}

#menuToggle input {
  display: none;
  width: 40px;
  height: 32px;
  position: absolute;
  top: -7px;
  left: -5px;
  cursor: pointer;
  opacity: 0;
  z-index: 2;
  -webkit-touch-callout: none;
}

.menu_toggle_spans{
  background-color: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(10px);
  width: 100px;
  height: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  gap: 5px;
  cursor: pointer;
  position: relative;
  z-index: 1;
  padding-right: 10px;
}

#menuToggle >div>span {
  display: block;
  width: 33px;
  height: 4px;
  /* margin-bottom: 5px; */
  position: relative;
  background: #cdcdcd;
  border-radius: 3px;
  z-index: 1;
  transform-origin: 4px 0px;
  transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1.0),
    background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1.0),
    opacity 0.55s ease;
    background-color: white(0, 0, 0, 0.607);
    backdrop-filter: blur(10px);
}

#menuToggle >span:first-child {
  transform-origin: 0% 0%;
}

#menuToggle >span:nth-last-child(2) {
  transform-origin: 0% 100%;
}

#menuToggle input:checked ~ span {
  opacity: 1;
  transform: rotate(45deg) translate(-2px, -1px);
  background: white;
}

#menuToggle input:checked ~ span:nth-last-child(3) {
  opacity: 0;
  transform: rotate(0deg) scale(0.2, 0.2);
}

#menuToggle input:checked ~ span:nth-last-child(2) {
  transform: rotate(-45deg) translate(0, -1px);
}

.burger_menu_content {
  position: fixed;
  width: 300px;
  /* margin: -100px 0 0 -50px; */
  left: 0;
  top: 0;
  padding: 50px;
  padding-top: 125px;
  background: rgba(0, 0, 0, 0.61);
  list-style-type: none;
  -webkit-font-smoothing: antialiased;
  transform-origin: 0% 0%;
  transform: translate(-100%, 0);
  transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1.0);
  backdrop-filter: blur(10px);
}

.burger_menu_content ul {
  flex-direction: column;
}

.burger_menu_content ul li {
  padding: 10px 0;
}

.burger_menu_content ul li a {
  color: var(--second_font_color);
}

#menuToggle input:checked ~ .burger_menu_content {
  transform: none;
}

.burger_menu_icons_div{
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  margin-top: 30px;
}
.burger_menu_icons_div a{
  color: var(--main_font_color);
}

.burger_menu_icons_div .lng-list{
  top: -90px;
  left: -35px;
  transform-origin: bottom center;
}


.burger_menu_icons{
  display: flex;
  justify-content: space-between;
  width: 100%;
}

@media (max-width: 1024px) {
  .burger_menu {
    display: block;
  }
}