#button {
   display: flex;
   justify-content: center;
   align-items: center;
   width: 50px;
   height: 50px;
   text-align: center;
   border-radius: 4px;
   position: fixed;
   bottom: 10px;
   right: 30px;
   border: 2px solid var(--second_font_color);
   transition: background-color 0.3s, opacity 0.5s, visibility 0.5s;
   opacity: 0;
   visibility: hidden;
   z-index: 1000;
}

#button:hover {
   background-color: #f7eee4;
}

#button:hover > svg {
   cursor: pointer;
}

/* Styles for the content section */
@media (min-width: 500px) {
   #button {
      margin: 30px;
   }
}
