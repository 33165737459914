.register_page{
    min-height: 100vh;
}
.register_page .container{
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    padding-bottom: 100px;

    padding-top: 100px; 
}

.register_block{
    display: flex;
    flex-direction: column;
    gap: 50px;
    width: 372px;
}

.register{
    display: flex;
    flex-direction: column;
    gap: 40px;
    width: 100%;
}

.register >div{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.register >div p{
    color: red;
}

.register_block_navigation{
 display: flex;
 width: 100%;
}


.register_block_navigation a{
    color: black;
    display: block;
    width: 48%;
    flex-direction: row;
    text-align: center;
}

.active-element{
    border-bottom: 1px solid black;
    padding-bottom: 5px;
}




.register div input{
    width: 100%;
    height: 30px;
    border: none;
    border-bottom: 1px solid #AAAA;
    padding: 0 5px;
    outline: none;
}

::placeholder {
    color: #AAAA;
    opacity: 1; /* Firefox */
  }

.register  .gender_inp{
    display: flex;
    gap: 20px;
    flex-direction: column;
}

.register  .gender_inp div{
    display: flex;
    gap: 20px;
}
.register .gender_inp label{
    display: flex;
    align-items: center;
    gap: 10px;
}

.register .gender_inp input{
    width: 20px;
    height: 20px;
}

.country_div{
    background-color: white;
    width: 100%;
    position: absolute;
    z-index: 999;
    overflow: hidden;
    transform: rotateX(90deg);
    transform-origin: top center;
    transition: all .5s;
    box-shadow: 0px 8px 5px 0px  #b2885165;
    overflow-y: auto;
    height: 300px;
}

.country_div::-webkit-scrollbar {
   width: 0;
}

.country_div div{
    text-align: center;
    padding: 10px 0;
    /* color: var(--main_font_color); */
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.096);
    transition: all .5s;
    width: 100%;
    cursor: pointer;
    color: gray;
}
.country_div div:hover{
    transform: scale(1.2);
color: black;
}

.country-inp{
    position: relative;
}

.selectIcon{
    position: absolute;
    right: 10px;
    top: 10px
}

.country-inp input:focus +div{
    transform: rotateX(0);
}

.register_btn{
    width: 100%;
    height: 44px;
    background-color: #D5AA72;
    border: none;
    outline: none;
    color: var(--main_font_color);
}

.register_btn:hover{
    background-color: #B28851;
}


@media(max-width: 480px){
    .register_block{
        width: 290px;
    }
}